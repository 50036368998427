//  Converted Variables
@import './auxs';


//  Custom Media Query Variables

//  --------------------------------------------------
//  SCA - Webview
//  --------------------------------------------------
html,
body,
// .layout,
#root {
  height: 100%;
}

// ::-webkit-scrollbar {
//   display: none;
// }

// ::-webkit-scrollbar {
// 	width: calc-rel(10px);

// }

// /* Track */
// ::-webkit-scrollbar-track {
// 	background: transparent; 
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
// 	border-radius: $border-radius-lg;
// 	background: rgba($body-color, .3); 
// 	cursor: pointer;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
// 	background: rgba($body-color, .4);
// }

.view,
.detail,
.wrapper {
  flex-direction: column;
  display: flex;
  height: 100%;
  z-index: 0;
}

.view,
.wrapper {
  flex: 1;
  overflow: hidden;
  overflow-y: auto;
}

.view:not(.show) {
	--#{$prefix}offcanvas-height: $offcanvas-vertical-height;
	position: fixed;
	right: 0;
	left: 0;
	bottom: 0;
	height: var(--#{$prefix}offcanvas-height);
	max-height: 100%;
	width: 100%;
	z-index: 1020;
	opacity: 0;
	transition: opacity 0.35s ease, top 0.35s ease ;
	overflow-y: auto;
}

.view {	
	@at-root .show-view #{&},
	&.active {
		// background-color: transparent;
		box-shadow: 0 0 $spacer * 2 rgba($body-color, 0.2);
		opacity: 1;
		top: 0;
		&:before {
			content: "";
			position: fixed;
			bottom: 0;
			right: 0;
			top: 0;
			left: 0;
			z-index: -1;
			background-color: rgba($black, .66);
		}

		.view-header,
		.support-header,
		.supplier-header {
			margin-top: $spacer * 2;
			// position: sticky;
			top: 0;
			// z-index: 1;
			.card { margin-bottom: 0;}
		}

		.view-body,
		.supplier-body,
		.support-body {
			min-height: 100%;
		}
	}

	@at-root .show-offcanvas #{&}{
		transform: none;
		visibility: visible;
	}
}

.offcanvas-all {
	box-shadow: $box-shadow-md !important;
	.h-calc{
		height: calc(100% - 4rem);
	}
}

.show-offcanvas,
.show-view {
	overflow: hidden;
	height: auto;
	position: relative;
	top: - ($border-width * 2);
	// z-index: 1031;
	> .navbar {
		pointer-events: none;
		user-select: none; 
	}
	// .supplier {
	// 	min-height: 97vh;
	// }
	.container-list{
		overflow: hidden;
		height: 0;
	}

	.offcanvas:before {
		content: "";
		position: fixed;
		bottom: 0;
		right: 0;
		top: 0;
		left: 0;
		z-index: -1;
		background-color: rgba($black, .66);
	}
}


// Layout
// ==========================================
.block{
	display: block;
	position: relative;
	@include clearfix;
}

.scrollbar{
    overflow-y: auto;
	/* width */
	&::-webkit-scrollbar {
		width: calc-rel(10px);
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: transparent; 
	}
	
	/* Handle */
	&::-webkit-scrollbar-thumb {
		border-radius: $border-radius-lg;
		background: rgba($body-color, .1); 
		cursor: pointer;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: rgba($body-color, .2);
	}
}

.list {
	position: relative;
	padding: 0;
}

// .container-list {
// 	@include media-breakpoint-up(xl){
// 		margin-left: calc-rel(204px);
// 	}

// 	.circle:not(:disabled):not(.disabled):hover,
// 	.square:not(:disabled):not(.disabled):hover {
// 		cursor: pointer;
// 	}
// }

@include media-breakpoint-up(xl){
	.col-min{
		min-width: calc-rel(222px);
	}
	.container-list {
		// margin-left: calc-rel(238px);
		padding-left: calc-rel(238px);
		margin-left: 0;
	}
}

.cover {
	color: var(--#{$prefix}cover-color);
	// background-image: -webkit-linear-gradien(to right, var(--#{$prefix}cover, var(--#{$prefix}tertiary-bg, $stage)), var(--#{$prefix}cover, var(--#{$prefix}secondary-bg, $light)));
	// background-image: linear-gradient(to right, var(--#{$prefix}cover, var(--#{$prefix}tertiary-bg, $stage)), var(--#{$prefix}cover, var(--#{$prefix}secondary-bg, $light)));
	$status-color: if(contrast-ratio($primary) > $min-contrast-ratio, var(--#{$prefix}white-rgb), var(--#{$prefix}dark-rgb));
	background-image: linear-gradient(90deg, rgba(#{$status-color}, .188), rgba(#{$status-color}, .188));
	background-repeat: repeat;
	background-color: var(--#{$prefix}cover, var(--#{$prefix}tertiary-bg, $stage));
	border: 0 !important;
	&::-webkit-scrollbar {
		width: $spacer;
		height: $spacer;
	}
	&::-webkit-scrollbar-thumb {
		background: rgba($body-color, 0.25);
		border: ($border-width * 2) solid $light;
		border-radius: $border-radius;
	}
	&::-webkit-scrollbar-track {
		background: $light;
		border-radius: $border-radius * 2;
	}
	
}

.circle,
.square {
	position: relative;
	display: inline-block;
	display: flex;
	background-color: rgba($black, 0.085);
	width: calc-rel(82px);
	height: calc-rel(82px);
	z-index: 0;

	&.is-loading {
		padding: 0.25rem;

		&:before {
			background: none;
			border-color: rgba($white, 0.77);
		}

		.agents-small {
			background: none !important;
		}
	}
	.agents-small {
		margin-top: auto;
		margin-bottom: auto;
		&.is-loading {
			height: inherit;
			border-radius: inherit;
		}
	}
	+ .circle,
	+ .square {
		margin-left: $spacer * .75;
	}

	&:before {
		content: "";
		position: absolute;
		border-radius: inherit;
		border: ($spacer * .25) solid rgba($body-color, 0.033);
		background: $body-bg;
		margin: ($spacer * .125);
		bottom: 0;
		right: 0;
		left: 0;
		top: 0;
		z-index: -1;
	}
}

.circle {
	border-radius: $border-radius-pill;
}

.square {
	border-radius: $border-radius;
}

// Favorites
// ==========================================
.stars {
	display: block;
	position: relative;
	padding-top: map-get($spacers, 5);
	padding-bottom: map-get($spacers, 4);
	@include clearfix;
}

.stars-scrollbar {
	position: relative;
	white-space: nowrap;
	overflow-x: auto;
	padding-bottom: division(map-get($spacers, 1), 2);
	cursor: -webkit-grab;
	cursor: grab;
	&::-webkit-scrollbar {
		width: 0.5rem;
		height: 0.5rem;
	}
	&::-webkit-scrollbar-thumb {
		background: rgba($body-color, 0.05);
		border: 0.125rem solid rgba($white, 0.5);
		border-radius: $border-radius;
	}
	&::-webkit-scrollbar-track {
		background: rgba($white, 0.5);
		border-radius: $border-radius * 2;
	}
	a,
	.card {
		text-decoration: none;
		color: currentColor;
	}
	.card {
		min-width: auto;
	}
}

.star-item {
	display: inline-block;
	max-width: calc-rel(82px);
	text-align: center;
	&:before {
		display: none;
	}
	+ .stars-item {
		margin-left: 0.5rem;
	}
}


// Sync
// ==========================================


// Suppliers
// ==========================================
.supplier-body {
	display: block;
	margin-left: 5rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	.agent-container {
		width: auto;
		position: absolute;
		margin-left: -5.5rem;
		&:not(:only-child) {
			top: 50%;
			margin-top: -1.5rem;
		}
	}
}

.supplier-agent,
.supplier-accounts{
	display: inherit;
	position: relative;
}

.supplier-agent{
	display: flex;
	float: left;
}

.supplier-name{
	max-width: 100%;
	@include text-truncate;
	float: left;
}

.supplier-item,
.supplier-header {
	display: inherit;
    flex-direction: column;
	circle,
	.square{
		vertical-align: top !important;
		width: calc-rel(42px);
		min-width: calc-rel(42px);
		height: calc-rel(42px);
		background: $white;
		&:not(.supplier-agent){
			top: 50%;
			margin-top: division((- calc-rel(42px)),2);
			position: absolute;
		}
		&:before{
			border-color: transparent;

		}
		.agents-small {
			zoom: .5;
		}
		.supplier-name{
			max-width: 100%;
			padding-left: 6rem;
		}
	}
}

.supplier,
.supplier-item {
	@include clearfix;
	position: relative;
	// display: flex;
	// flex-direction: column;
	clear: both;

	&.progress{
		display: flex;
		padding: 0;
		background-color: $light;
		height: $spacer * .75;
		.progress-bar{
			height: 100%;
		}
		.progress-bar-dark{
			@include gradient-striped(rgba($black, .075));
		}
	}
}

.supplier-item {
	&:not(.show):not(.progress) {
		// border-radius: $border-radius;
		padding-top: map-get($spacers, 2);
		padding-bottom: map-get($spacers, 2);
		border-bottom: $border-width solid;
		border-color: var(--#{$prefix}tertiary-bg, $stage);
		background-color: var(--#{$prefix}body-bg);
		&.active{
			color: currentColor;
			// margin-top: 0; 
			border-bottom-left-radius: $border-radius;
			border-bottom-right-radius: $border-radius;
			+ .supplier-item:not(.active){
				border-top-width: $border-width;
				margin-top: $spacer;
				border-top-left-radius: $border-radius;
				border-top-right-radius: $border-radius;
			}
		}
		// &:not(:last-of-type){
		// 	margin-bottom: $border-width;
		// }

		&:focus,
		&:hover {
			border-color: var(--#{$prefix}tertiary-bg, $stage);
			z-index: 3;
		}
		&:focus,
		&:hover {
			background-color: var(--#{$prefix}tertiary-bg, $stage);
		}
		// &:not(.active).is-loading .btn:not(.bg-none),
		// &:focus .btn:not(.bg-none),
		// &:hover .btn:not(.bg-none) {
		// 	background-color: $white !important;
		// 	border-color: $white !important;
		// }
		&.progress .agents-small {
			background-color: $gray-200;
			background-image: none !important;
		}

	}

	&.show {
		// height: inherit;
		// min-height: 100vh;
		display: flex;
		flex-direction: inherit;
	}
}


.supplier {
	display: flex;
	flex-direction: column;
}

// Icons
// ==========================================
// $thumbnail-padding:				.25rem !default;
// $thumbnail-bg:					$body-bg !default;
// $thumbnail-border-width:		$border-width !default;
// $thumbnail-border-color:		#ddd !default;
// $thumbnail-border-radius:		$border-radius !default;
// $thumbnail-box-shadow:			0 1px 2px rgba($black,.075) !default;
// $thumbnail-transition:			all .2s ease-in-out !default;
// $thumbnail-font-name: 			$material-font-name !default;

.thumbnail-item{
	position: relative;

	// color: inherit;
	background-color: rgba($body-color, .05);
	background-size: cover;
	background-position: center;
	border-radius: $border-radius;

	font-size: $font-size-base - $border-width;
	padding-left: map-get($spacers, 2);
	padding-right: map-get($spacers, 2);

	width: $spacer * 2;
	height: $spacer * 2;
	line-height: $spacer * 2;

	 // Part 1: Set a maximum relative to the parent
	max-width: 100%;
	// Part 2: Override the height to auto, otherwise images will be stretched
	// when setting a width and height attribute on the img element.
	height: auto;

	&:before{
        vertical-align: middle;
		display: inline-block;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	&:empty {
		min-height: $spacer * 2;
		min-width: $spacer * 2;
	}
}

@include make-genesis(thumbnail, (color, background-color));