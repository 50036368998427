/* --- Functions --- */
@use "sass:math";
// @use "~bootstrap/scss/functions.scss";

// Units
// ====================
@function to-number($value) {
	// @ref: https://stackoverflow.com/questions/47630616/scss-arithmetic-operation-with-string
	$value: unquote('#{$value}');

	@if type-of($value)=='number' {
		@return $value;
	}

	@else if type-of($value) !='string' {
		@warn '#{$value} for `to-unit` should be a number or a string.';
	}

	$units: ('px': 1px, 'cm': 1cm, 'mm': 1mm, '%': 1%, 'ch': 1ch, 'pc': 1pc, 'in': 1in, 'em': 1em, 'rem': 1rem, 'pt': 1pt, 'ex': 1ex, 'vw': 1vw, 'vh': 1vh, 'vmin': 1vmin, 'vmax': 1vmax);
	$parsed-unit: false;

	@each $unit in $units {
		// str-index - find substring in a string
		// 'px' in '10px' for example

		// $unit is a pair of ['px': 1px] (item in $units)
		// nth(['px': 1px], 1) returns 'px'
		// nth(['px': 1px], 2) returns 1px

		@if (str-index($value, nth($unit, 1))) {
			$parsed-unit: nth($unit, 2);
		}
	}

	@if (not $parsed-unit) {
		@error 'Invalid unit `#{$value}`.';
	}

	@return $parsed-unit;
}

@function division($dividend, $divisor, $precision: 10) {
	@if function-exists('math.div') or function-exists('div') {
		@return math.div($dividend, $divisor);
	}

	@else if function-exists('divide') {
		@return divide($dividend, $divisor, $precision);
	}

	@else {
		// this portion was removed from https://github.com/twbs/bootstrap/blob/main/scss/_functions.scss
		$sign: if($dividend > 0 and $divisor > 0 or $dividend < 0 and $divisor < 0, 1, -1);
		$dividend: abs($dividend);
		$divisor: abs($divisor);

		@if $dividend ==0 {
			@return 0;
		}

		@if $divisor ==0 {
			@error "Cannot divide by 0";
		}

		$remainder: $dividend;
		$result: 0;
		$factor: 10;

		@while ($remainder > 0 and $precision >=0) {
			$quotient: 0;

			@while ($remainder >=$divisor) {
				$remainder: $remainder - $divisor;
				$quotient: $quotient + 1;
			}

			$result: $result * 10 + $quotient;
			$factor: $factor * .1;
			$remainder: $remainder * 10;
			$precision: $precision - 1;

			@if ($precision < 0 and $remainder >=$divisor * 5) {
				$result: $result + 1;
			}
		}

		$result: $result * $factor * $sign;
		$dividend-unit: unit($dividend);
		$divisor-unit: unit($divisor);
		$unit-map: (
			"px": 1px,
			"rem": 1rem,
			"em": 1em,
			"%": 1%
		);

	@if ($dividend-unit !=$divisor-unit and map-has-key($unit-map, $dividend-unit)) {
		$result: $result * map-get($unit-map, $dividend-unit);
	}

	@return $result;
}
}

// @desc Remove the unit
/// Strips the units from strings
/// @param {string|number} $number - The number to remove units from.
/// @return {number} Unitless number.
/// @access public
/// @link https://github.com/nemd/sass/issues/533
@function unit-strip($value, $relate-to-rootbase: true, $root-base: if(variable-exists(root-px), $root-px, 16px)) {
	@if type-of($value)=='number' {
		$result: division($value, ($value * 0 + 1));

		@if unitless($value) or not $relate-to-rootbase {
			@return $result;
		}

		@else {
			$root: division($root-base, ($root-base * 0 + 1));
			@return division($result, $root);
		}
	}

	@else {
		@warn 'Not a number value: #{$value}';
		@return $value;
	}
}

/// Takes in an absolute unit and gives back a relative one.
/// @param {number} $value - A absolute unit.
/// @access public
/// @return {number} A relative unit based on the values of `$root-unit`, `if(variable-exists(enable-unit), $enable-unit, true)`.
@function calc-rel($value, $root-unit: if(if(variable-exists(enable-unit), $enable-unit, true), if(variable-exists(root-unit), $root-unit, rem), px), $root-base: if(variable-exists(root-px), $root-px, 16px)) {
	$root-unit: if(type-of($root-unit)=='number', unit($root-unit), $root-unit);
	$root-base: if($root-unit =='em', 16px, $root-base);

	$unit: to-number($root-unit);

	@if if(variable-exists(enable-unit), $enable-unit, true) or if(variable-exists(enable-unit), $enable-unit, true) !=false {
		@if type-of($value)=='number' {
			$value: if(unit($value) !=unit($root-base),
					(unit-strip($value, false) * $root-base),
					$value);

			@if unit($value)==$root-unit {
				@return $value;
			}

			@else {
				@if not $root-unit =='em' or not $root-unit =='rem' or not $root-unit =='%' {
					@return null;
				}

				@if unit($value) !=$root-unit {

					@if type-of($value) !='number' or (unit($value)=='%') {
						@return $value;
					}

					@if not unitless($value) {
						$value: unit-strip($value, false);
					}

					@if not unitless($root-base) {
						$root-base: unit-strip($root-base, false);
					}

					@if($root-unit =='%') {
						@return percentage(division($value, $root-base));
					}

					@else {
						@return (division($value, $root-base) * $unit);
					}
				}

				@else {
					@return $value;
				}
			}
		}

		@else {
			@return $value;
		}
	}

	@else {
		@return $value; // ignores value
	}
}

// Strings
// ====================
@function str-replacer($string, $search, $replace: "") {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1)+$replace + str-replacer(str-slice($string, $index + str-length($search)),$search,$replace);
	}

	@return $string;
}

@function font-url($value, $find: null, $rep: null){
$value: unquote("#{$value}");

@if $find !=null {
	$find: unquote("#{$find}");
	$rep: unquote("#{$rep}");
}

@if $find ==null {
	$find: unquote(" ");
	$rep: unquote("+");
}

$result: str-replace($value, $find, $rep);
@return unquote("#{$result}");
}

// scss-docs-start color-functions
// Tint a color: mix a color with white
@function tint-color($color, $weight) {
	@return mix(white, $color, $weight);
}

// Shade a color: mix a color with black
@function shade-color($color, $weight) {
	@return mix(black, $color, $weight);
}

// Shade the color if the weight is positive, else tint it
@function shift-color($color, $weight) {
	@return if($weight > 0, shade-color($color, $weight), tint-color($color, -$weight));
}

@function color-contrast($color) {
	@if function-exists('color-contrast') {
		@return color-contrast($color);
	}

	@else if function-exists('color-yiq') {
		@return color-yiq($color);
	}

	@else {
		@return $color;
	}
}

@mixin scrollbar($size, $foreground-color, $background-color: null, $radius: $size, $border: division($size, 4)) {
	@if $background-color ==null {
		$background-color: if(type-of($foreground-color) !=color, $foreground-color , rgba(color-contrast($foreground-color), .2));
	}

	&::-webkit-scrollbar {
		width: $size;
		height: $size;
	}

	&::-webkit-scrollbar-thumb {
		background: $background-color;
		border: $border solid if(type-of($foreground-color) !=color, transparent, $foreground-color);
		border-radius: $radius;
	}

	&::-webkit-scrollbar-track {
		background: if(type-of($foreground-color) !=color, transparent, $foreground-color);
		border-radius: $radius;
	}
}
@import 'bootstrap/scss/_functions.scss';


/* --- Variables --- */

// Options
// ====================
$enable-ie-compliance:			false !default;
$enable-negative-margins:		true !default;
$enable-shadows:				false !default;
$variable-prefix:			    'sca-' !default;
$prefix:                        $variable-prefix !default;

// Colors
// ====================
$black: #030303 !default;
$gray-900: #161819 !default;
$gray-800: #292c2f !default;
$gray-700: #4f555b !default;
$gray-600: #626970 !default;
$gray-500: #959ca3 !default;
$gray-400: #b6bbbf !default;
$gray-300: #d7d9dc !default;
$gray-200: #edeeef !default;
$gray-100: #f5f5f6 !default;
$stage: #fafbfb !default;
$white: #ffffff !default;

// Colors: Social
$facebook: #3b5998 !default;
$twitter: #55acee !default;
$google: #dd4b39 !default;
$linkedin: #0077b5 !default;
$pinterest: #bd081c !default;
$tumblr: #36465d !default;
$youtube: #FF0000 !default;
$instagram: #000000 !default;
$buzzfeed: #e32e32 !default;
$snapchat: #fffc00 !default;
$vine: #00b488 !default;

$social-colors: (
    ) !default;
// stylelint-disable-next-line scss/dollar-variable-default
$social-colors: map-merge((facebook: $facebook,
            twitter: $twitter,
            google: $google,
            linkedin: $linkedin,
            pinterest: $pinterest,
            tumblr: $tumblr,
            youtube: $youtube,
            instagram: $instagram,
            buzzfeed: $buzzfeed,
            snapchat: $snapchat,
            vine: $vine),
        $social-colors
);


$blue: mix(#2171d5, #307fe2, 68%) !default; // #307fe2 !default; // mix(#2171d5, #307fe2, 68%) !default;
$purple: #7137E6 !default;
$indigo: #3F51B5 !default;
$cyan: #3db1d4 !default;
$teal: #2C6369 !default;
$green: #0BBA86 !default; // mix(#1bc09a, #20ca89) !default; // #28bd99 !default; //
$yellow: #fcb755 !default; // #ffd256 !default; //
$red: #d73a49 !default; // #ff7161 !default; //
$pink: #ed64ab !default;
$orange: #ff4500 !default;
$stage: #fafbfb !default;

$accent:							$pink;
$accent-text-emphasis:				shade-color($accent, 60%) !default;
$accent-bg-subtle:					tint-color($accent, 80%) !default;
$accent-border-subtle:				tint-color($accent, 60%) !default;
$light:								$gray-100;
$dark:								$gray-900;


$sca-colors: (
    "accent": $accent,
    "default": $gray-400,
    "faded": $gray-200,
    "stage": $stage,
    "dim": shift-color($gray-100, 2%),
    "burn": shade-color($gray-900, 10%),
) !default;

$auxs-colors: (
    "inherit": inherit,
    "none": transparent,
    "cc": currentColor,
    ) !default;

// $blue: 										#307fe2 !default;
// $teal:										#28bd99 !default;
// $yellow:									#ffd256 !default;
// $red:										#ff7161 !default;

// $primary:									$blue !default;
// $success:									$green !default;
// $danger:									$red !default;
// $warning:									$yellow !default;


// Global
$body-color:						$gray-800;
$body-bg:							$white !default; // #404055 !default;
$body-text-align:					null !default;
$link-color:						$gray-800 !default;
$link-color-dark:					$gray-100 !default;

$border-color:						rgba($gray-500, .3);
$border-width:						calc-rel(1px);
$border-widths: (
	5: 								($border-width*5),
	4: 								($border-width*4),
	3: 								($border-width*3),
	2: 								($border-width*2),
	1: 								($border-width),
) !default;

// Layout
$grid-gutter-width:					calc-rel(24px);
$grid-breakpoints: (
    xs: 0,
    sm: calc-rel(576px, em),
    md: calc-rel(768px, em),
    lg: calc-rel(1024px, em),
    xl: calc-rel(1368px, em),
    hd: calc-rel(1580px, em)
);

$container-max-widths: (
    sm: calc-rel(540px, em),
    md: calc-rel(720px, em),
    lg: calc-rel(960px, em),
    xl: calc-rel(1280px, em),
    hd: calc-rel(1320px, em)
) !default;



// Typography
$root-font-base:					'Poppins' !default;
$root-font-headings:				'Montserrat' !default;

$font-family-sans-serif:			'Manrope', Helvetica, Arial, sans-serif !default;
$font-family-serif:					'PT Serif', Georgia, "Times New Roman", Times, serif !default;
$font-family-mono:					Menlo, Monaco, Consolas, "Courier New", monospace !default;
$font-family-fallback:				-apple-system, BlinkMacSystemFont, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$font-family-base:					if(variable-exists('root-font-base'), (#{$root-font-base}, #{$font-family-fallback}), #{$font-family-fallback}) !default;
$headings-font-family:				if(variable-exists('root-font-headings'), if($root-font-headings, (var(--#{$prefix}headings-font-family, #{$root-font-headings}), #{$font-family-fallback}), null) , null) !default;

$font-size-base:					calc-rel(16px)!default;
$font-size-book:					$font-size-base - $border-width !default;
$font-size-aux:						calc-rel(14px) !default;
$font-size-hd:						$font-size-base * 1.5 !default;

// UI
$box-shadow-md:						0 calc-rel(16px) calc-rel(64px) rgba($dark, 0.44) !default;
$box-shadow-xl:						0 calc-rel(20px) calc-rel(48px) rgba($dark, .05) !default;
$box-shadow-hd:						0 calc-rel(15px) calc-rel(25px) rgba($black, 0.04), 0 calc-rel(20px) calc-rel(34px) - calc-rel(25px) rgba($dark, 0.05) !default;
$box-shadow-float:					0px 100px 80px rgba(var(--#{$prefix}dark-rgb), .07), 0px 41.7776px 33.4221px rgba(var(--#{$prefix}dark-rgb), 0.0503198), 0px 22.3363px 17.869px rgba(var(--#{$prefix}dark-rgb), 0.0196802) !default;

$offcanvas-vertical-height: 	97vh !default;
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_variables-dark.scss';

$theme-colors: map-merge($theme-colors, $sca-colors);

$body-color: $gray-800 !default;
// $body-secondary-color:      				rgba($body-color, .75) !default;
// $body-tertiary-color:       				rgba($body-color, .5) !default;

// $body-bg:                   				$white !default;
$body-secondary-bg: $stage !global;
$body-tertiary-bg: $gray-100 !global;


@import 'bootstrap/scss/_maps.scss';

$theme-colors-text:					map-merge($theme-colors, ("accent": $accent-text-emphasis));
$theme-colors-bg-subtle:			map-merge($theme-colors-bg-subtle, ("accent": $accent-bg-subtle));
$theme-colors-border-subtle:		map-merge($theme-colors-bg-subtle, ("accent": $accent-border-subtle));

$body-secondary-bg:					$gray-100;
$body-tertiary-bg:					$stage;

$nav-link-font-weight-px:			500 !default;
$nav-link-font-size-px:				$font-size-aux !default;

$form-feedback-warning-color:		transparent !default;
$form-feedback-icon-warning-color:	$warning !default;
$form-feedback-icon-warning:		url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'><path fill='#{$form-feedback-icon-warning-color}' d='M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z'/></svg>") !default;

$form-validation-states: map-merge(
	(
		"warning": (
			"color": 				$form-feedback-warning-color,
			"icon": 				$form-feedback-icon-warning
		)
	),
	$form-validation-states
);

@import 'bootstrap/scss/_mixins.scss';
@import 'bootstrap/scss/_utilities.scss';


// Generic
// ====================
$border-width: calc-rel(1px) !global;
$min-contrast-ratio: 1.75 !global;
$component-active-color: $primary !global;
$component-active-bg: $body-bg !global;

$font-size-common: $font-size-base - $border-width !default;
$font-size-book: $font-size-common !default;

$input-btn-font-family: $headings-font-family !global;
$input-btn-font-size: $font-size-sm !global;
$btn-font-weight: 500 !global;
$small-font-size: 75% !global;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.
$input-btn-padding-y: calc-rel(10px) !global;
$input-btn-padding-x: (
    $input-btn-padding-y * 2) + ($border-width * 2
);
$input-btn-font-family: $headings-font-family;
$input-btn-font-size: $font-size-common;
$input-btn-line-height: $line-height-base;

$input-btn-font-size-sm: calc-rel(13px);
$input-btn-padding-y-sm: division($input-btn-padding-y, 2);
$input-btn-padding-x-sm: $input-btn-padding-y;

$input-btn-padding-y-lg: (
    $input-btn-padding-x * .75) - $border-width;
$input-btn-padding-x-lg: calc-rel(20px
);

$input-btn-font-size-lg: $input-btn-font-size;
$input-btn-line-height-lg: $line-height-lg;

$btn-border-radius: $border-radius * 8 !global;
$btn-border-radius-lg: $btn-border-radius !global;

// Icons
// ------------------------------------------
$icns-font-family: null !default;
$icns-font-weight: 'inherit' !default;
$icns-font-size: $font-size-base !default;

// Icns: UTF
// encoding this as utf8 is good!
// https://css-tricks.com/probably-dont-base64-svg/
// but like url encode it first or IE/FF will hate you
// http://codepen.io/yoksel/details/JDqvs/
// svg icons saved as variables

$icns-lsaquo: "\2039";
$icns-rsaquo: "\203A";
$icns-laquo: "\00ab";
$icns-raquo: "\00bb";
$icns-mdash: "\2014";
$icns-ndash: "\2013";
$icns-hyphen: "\2010";
$icns-bull: "\2022";
$icns-whibull: "\25E6";
$icns-hellip: "\2026";
$icns-vellip: "\22EE";
$icns-midhellip: "\22EF";
$icns-gt: "\003e";
$icns-lt: "\003c";
$icns-times: "\00D7";
$icns-bigtimes: "\2715";
$icns-boldtimes: "\2716";
$icns-utri: "\25b2";
$icns-dtri: "\25bc";
$icns-ltri: "\25c0";
$icns-rtri: "\25b6";
$icns-ustri: "\25b4";
$icns-dstri: "\25be";
$icns-lstri: "\25c2";
$icns-rstri: "\25b8";
$icns-diamond: "\25c6";
$icns-fisheye: "\25c9";
$icns-bullseye: "\25ce";
$icns-circle: "\25cf";
$icns-whitecircle: "\25cb";
$icns-square: "\25a0";
$icns-whitesquare: "\25a1";
$icns-ssquare: "\25aa";
$icns-swhitesquare: "\25ab";
$icns-arrow-chevron-right: "\203A";
$icns-arrow-chevron-left: "\2039";
$icns-arrow-chevron-up: "\2303";
$icns-arrow-chevron-down: "\2304";
$icns-arrow-forward-left: "\2190";
$icns-arrow-forward-up: "\2191";
$icns-arrow-forward-right: "\2192";
$icns-arrow-forward-right-rounded: "\279c";
$icns-arrow-forward-down: "\2193";
$icns-empty: "\2300";
$icns-upload: "\2305";
$icns-wave: "\2307";
$icns-go-right: "\27a4";
$icns-account-circle-outline: "\263a";
$icns-account-circle-outline: "\263a";
$icns-forbidden: "\2298";
$icns-search: "\1f50d";
$icns-phone: "\260e";
$icns-frequently-asked-questions: "\fe56";

$icns-map: (
    ) !default;
$icns-map: map-merge(('lsaquo': $icns-lsaquo,
            'rsaquo': $icns-rsaquo,
            'laquo': $icns-laquo,
            'raquo': $icns-raquo,
            'mdash': $icns-mdash,
            'ndash': $icns-ndash,
            'hyphen': $icns-hyphen,
            'bull': $icns-bull,
            'whibull': $icns-whibull,
            'hellip': $icns-hellip,
            'vellip': $icns-vellip,
            'midhellip': $icns-midhellip,
            'gt': $icns-gt,
            'lt': $icns-lt,
            'times': $icns-times,
            'close': $icns-bigtimes,
            'boldtimes': $icns-boldtimes,
            'utri': $icns-utri,
            'dtri': $icns-dtri,
            'ltri': $icns-ltri,
            'rtri': $icns-rtri,
            'ustri': $icns-ustri,
            'dstri': $icns-dstri,
            'lstri': $icns-lstri,
            'rstri': $icns-rstri,
            'diamond': $icns-diamond,
            'fisheye': $icns-fisheye,
            'bullseye': $icns-bullseye,
            'circle': $icns-circle,
            'whitecircle': $icns-whitecircle,
            'square': $icns-square,
            'whitesquare': $icns-whitesquare,
            'ssquare': $icns-ssquare,
            'swhitesquare': $icns-swhitesquare,
            'chevron-right': $icns-arrow-chevron-right,
            'chevron-up': $icns-arrow-chevron-up,
            'arrow-left': $icns-arrow-forward-left,
            'arrow-up': $icns-arrow-forward-up,
            'arrow-right': $icns-arrow-forward-right,
            'arrow-right-rounded': $icns-arrow-forward-right,
            'arrow-down': $icns-arrow-forward-down,
            'account-circle-outline': $icns-account-circle-outline,
            'message': $icns-hellip,
            'forbidden': $icns-forbidden,
            'empty': $icns-empty,
            'upload': $icns-upload,
            'search': $icns-search,
            'wave': $icns-wave,
            'phone': $icns-phone,
            'frequently-asked-questions': $icns-frequently-asked-questions),
        $icns-map
);

// Thumbails
// ------------------------------------------

// Thumbnail: colors
$folder: $body-color !default;
$shared: #81bee8 !default;
$folder-linked: #20C7A1 !default;
$folder-favorite: if(variable-exists('warning'), $warning, #ffd700) !default;
$groups: #FF9B82 !default;
$image: #FB87DA !default;
$text: #2b82f4 !default;
$spreadsheet: #7ED321 !default;
$presentation: #F65B14 !default;
$disk: #ABD321 !default;
$pdf: #EB0000 !default;
$coded: #41C7A9 !default;
$image-vector: #F5C923 !default;
$audio: #2BC5F4 !default;
$incomplete: #D3A29F !default;
$video: #DF87FB !default;
$font: $gray-500 !default;
$calendar: $body-color !default;
$email: $body-color !default;
$email-open: #8bc34a !default;
$compressed: $body-color !default;
$dashboard: if(variable-exists('info'), $info, #0f65ef) !default;
$radar: $body-color !default;
$card: $body-color !default;
$app: $body-color !default;
$wallet: #36c8af !default;

$thumbnail-colors: (
    ) !default;
$thumbnail-colors: map-merge(('folder': $folder,
            'shared': $shared,
            'folder-linked': $folder-linked,
            'folder-favorite': $folder-linked,
            'image': $image,
            'text': $text,
            'spreadsheet': $spreadsheet,
            'calendar': $calendar,
            'email': $email,
            'email-open': $email-open,
            'presentation': $presentation,
            'disk': $disk,
            'font': $font,
            'pdf': $pdf,
            'acrobat': $pdf,
            'code': $coded,
            'image-vector': $image-vector,
            'audio': $audio,
            'compressed': $compressed,
            'incomplete': $incomplete,
            'video': $video,
            'wallet': $wallet,
            'team': $groups,
            'users': $info,
            'app': $primary,
            'radar': $radar,
            'dashboard': $dashboard,
            'accept': $success,
            'files': $folder,
            'card': $card,
            'suppliers': $info,
            'agents': $info),
        $thumbnail-colors
);

// Utilities
// ------------------------------------------
@mixin make-genesis($class: false, $atribute: color, $map: $thumbnail-colors, $and: false, $pseudo: true) {
    $class-append: if($class, '-', '');

    @each $item in $map {
        $name: nth($item, 1);
        $prop: if(nth($item, 2), nth($item, 2), nth($item, 1));

        @if $and {
            &.#{$class}#{$class-append}#{$name} {
                @if type-of($atribute)=='list' {
                    #{nth($atribute, 1)}: #{$prop};
                    #{nth($atribute, 2)}: #{rgba($prop, .075)};
                }

                @else {
                    #{$atribute}: #{$prop};
                }
            }
        }

        @else {
            .#{$class}#{$class-append}#{$name} {
                @if type-of($atribute)=='list' {
                    #{nth($atribute, 1)}: #{$prop};
                    #{nth($atribute, 2)}: #{rgba($prop, .075)};
                }

                @else {
                    #{$atribute}: #{$prop};
                }
            }
        }
    }
}

$utilities: map-merge($utilities,
        ("opacity": (property: opacity,
                class: o,
                responsive: true,
                state: hover,
                values: (0: 0,
                    33: .33,
                    44: .44,
                    50: .5,
                    66: .66,
                    75: .75,
                    88: .88,
                    100: 1,
                    inherit: inherit,
                )),
            "height": (property: height,
                class: h,
                values: (25: 25%,
                    50: 50%,
                    75: 75%,
                    100: 100%,
                    // 1: ($spacer * .375),
                    // 1: calc-rel(6px),
                    auto: auto,
                )),
            "letter-spacing": (property: letter-spacing,
                responsive: true,
                class: ls,
                values: (5: ($border-width*4.5),
                    4: ($border-width*3),
                    3: ($border-width*1.5),
                    2: ($border-width),
                    1: division($border-width, 1.5),
                    a: division($border-width, 3),
                    na: division(-$border-width, 3),
                    n1: division(-$border-width, 1.5),
                    n2: -($border-width),
                    n3: -($border-width*1.5),
                    n4: -($border-width*3),
                    n5: -($border-width*4.5),
                    none: 0)),
            "color": (property: color,
                class: text,
                state: (hover, focus, active),
                local-vars: ("text-opacity": 1),
                values: map-merge(map-get(map-get($utilities, "color"), "values"),
                    ( "body-44": rgba(var(--#{$prefix}body-color-rgb), .44),
                        "body-66": rgba(var(--#{$prefix}body-color-rgb), .66),
                        "body-75": rgba(var(--#{$prefix}body-color-rgb), .75),
                        "body-88": rgba(var(--#{$prefix}body-color-rgb), .88),
                        "cover": var(--#{$prefix}cover-color),
                        "cover-44": rgba(var(--#{$prefix}cover-color-rgb), .44),
                        "cover-66": rgba(var(--#{$prefix}cover-color-rgb), .66),
                        "cover-75": rgba(var(--#{$prefix}cover-color-rgb), .75),
                        "cover-88": rgba(var(--#{$prefix}cover-color-rgb), .88),
                    ))),
            "text-opacity": (css-var: true,
                class: text-opacity,
                state: hover,
                values: (0: 0,
                    10: .1,
                    25: .25,
                    50: .5,
                    75: .75,
                    100: 1)),
            "border-color": (property: border-color,
                responsive: true,
                class: border,
                state: hover,
                local-vars: ("border-opacity": 1),
                values: map-merge(map-get(map-get($utilities, "border-color"), "values"),
                    map-merge($auxs-colors, ("body": rgba(var(--#{$prefix}body-bg-rgb), var(--#{$prefix}bg-opacity)),
                            "body-secondary": rgba(var(--#{$prefix}secondary-bg-rgb), var(--#{$prefix}bg-opacity)),
                            "body-tertiary": rgba(var(--#{$prefix}tertiary-bg-rgb), var(--#{$prefix}bg-opacity)),
                        )),
                )),
            "border-opacity": (css-var: true,
                class: border-opacity,
                state: hover,
                values: (0: 0,
                    10: .1,
                    25: .25,
                    50: .5,
                    75: .75,
                    100: 1)),
            "background-color": (property: background-color,
                responsive: true,
                class: bg,
                state: hover,
                local-vars: ("bg-opacity": 1),
                values: map-merge(map-get(map-get($utilities, "background-color"), "values"),
                    map-merge($auxs-colors, ("body": rgba(var(--#{$prefix}body-bg-rgb), var(--#{$prefix}bg-opacity)),
                            "body-secondary": rgba(var(--#{$prefix}secondary-bg-rgb), var(--#{$prefix}bg-opacity)),
                            "body-tertiary": rgba(var(--#{$prefix}tertiary-bg-rgb), var(--#{$prefix}bg-opacity)),
                        )),
                )),
            "bg-opacity": (css-var: true,
                class: bg-opacity,
                state: hover,
                values: (0: 0,
                    5: .05,
                    10: .1,
                    25: .25,
                    50: .5,
                    75: .75,
                    90: .90,
                    100: 1)),
            "z-index": (property: z-index,
                responsive: true,
                state: hover,
                class: z,
                values: (3: 3,
                    2: 2,
                    1: 1,
                    0: 0,
                    n1: -1,
                    n2: -2,
                    above: (1020 + 1),
                    golden: (1030 + 2),
                    max: 2147483647)),
            "rounded": map-merge(map-get($utilities, "rounded"),
                (values: map-merge(map-get(map-get($utilities, "rounded"), "values"),
                        (4: ($border-radius-lg * 2), 5: ($border-radius-lg * 4), inherit: inherit),
                    ),
                ),
            ),
            "flex-grow": (responsive: true,
                property: flex-grow,
                class: flex,
                values: (grow-0: 0,
                    grow-1: 1,
                    grow-2: 2,
                )),
            "flex-shrink": (responsive: true,
                property: flex-shrink,
                class: flex,
                values: (shrink-0: 0,
                    shrink-1: 1,
                    shrink-2: 2,
                ))));


                